.datatablemain {
  padding: 0 24px;
  margin-bottom: 40px;
}
.tablepaper {
  width: 100%;
  border: 1px solid #f0f0f0;
  box-shadow: -5px 10px 20px rgba(0, 0, 0, 0.05) !important;
  border-radius: 10px !important;
}


.tablepaper .css-1m1gozg-MuiTableContainer-root {
  max-height: none;
}

.scrolltable_body tr {
  display: flex;
  table-layout: fixed;
}
.scrolltable_body thead tr th,
.scrolltable_body tbody tr td {
  flex: 1 auto;
  width: 1px;
}

.scrolltable_body thead th {
  flex: 1 auto;
}
.scrolltable_body tbody {
  display: block;
  overflow-y: auto;
}

.scrolltable_body thead tr th:nth-child(7),
.scrolltable_body tbody tr td:nth-child(7) {
  flex: 1;
  display: flex;
  max-width: 0;
}
.scrolltable_body tbody tr td .MuiButtonBase-root {
  padding: 0;
}
