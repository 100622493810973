.stapaddetails {
  display: flex;
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0 0;
  justify-content: center;
  gap: 100px;
}

.stapaddetails li {
    color: #B5B5B5;
    cursor: pointer;
}

.stapaddetails li.active {
    color: #01A4EE;
}

.adddetailsmain {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.adddetailsmain .form-group {
  width: 48%;
  margin: 0;
}

.MuiFormControl-root,
.adddetailsmain .form-group select,
.adddetailsmain .form-group .form-control {
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin: 0;
}
.massigesdatepicker {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.datemassige {
  width: 48%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.MuiFormControl-root,
.datemassige .form-group {
  width: 100%;
}

.textareamodal {
  width: 48%;
}

.textareamodal textarea {
  height: 125px !important;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 15px;
  width: 91%;
  /* color: #000 !important; */
  font-size: 16px;
}
.textareamodal :disabled {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.38) !important;
  border-color: rgba(118, 118, 118, 0.3);
}

.fullwidthcontactdetails {
  width: 100%;
  margin-top: 10px;
  display: flex;
  gap: 20px;
  justify-content: center;
}
