.selectinput{
    line-height: normal!important;
    width:100%;
    height:35px;
    font-size:14px !important;
}

.selectinput .MuiSelect-select{ padding:0 0 0 10px !important;}
.selectinput .MuiOutlinedInput-notchedOutline{border:solid 1px green !important;}
.widthdashbord{width:72% !important;}


.success{color:#018A5E!important;}
.success .MuiOutlinedInput-notchedOutline{border:solid 1px green !important; height:35px;}
.success .MuiSelect-select{ padding:0 0 0 10px !important;}

.error{color:#D73A3A!important;}
.error .MuiOutlinedInput-notchedOutline{border:solid 1px #D73A3A !important; height:35px;}
.error .MuiSelect-select{ padding:0px 0 0 10px !important;}

.warning{color:#E29802!important;}
.warning .MuiOutlinedInput-notchedOutline{border:solid 1px#E29802 !important; height:35px;}
.warning .MuiSelect-select{ padding:0 0 0 10px !important;}