.toolbarmain {
    display: flex;
    align-items: center;
    border: 1px solid #E7E8FC;
    box-shadow: -5px 10px 20px rgba(0, 0, 0, 0.05) !important;
    border-radius: 10px!important;
}
.toolbar-icon-button {
    padding:8px 10px !important;

}
.avtarrounded{border-radius: 10px!important;}