.searchbar-main {
    padding: 30px 24px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
}

.leftsearchbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0 30px;
}

.serchicon {
    background: #01A4EE !important;
    border-radius: 5px !important;
    color: #fff !important;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.searchfield {
    font-style: italic;
    width: 498px;
}

.filterbtn {
    color: #01A4EE !important;
    background: #FFFFFF;
    border: 1px solid #F0F0F0 !important;
    box-shadow: -5px 10px 20px rgba(0, 0, 0, 0.05) !important;
    border-radius: 10px;
}

.filterbtn:hover {
    background: none !important;
}