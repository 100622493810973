.filtermolmain .MuiPaper-root {
    border-radius: 0;
}

.filterheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    position: relative;
}

.filterheader h2 {
    padding-bottom: 0;
}

.filterheader a {
    color: #D2222D;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
}

.filterheader::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    background: #F0F0F0;
    bottom: 0px;
    left: 0;
}

.tabfilter .tabscrollable {
    overflow: overlay;
    width: 100%;
}

.tabfilter .MuiTab-textColorPrimary {
    padding: 20px !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
}

.tabfilter .Mui-selected {
    color: #000 !important;
    background: #fff;
    font-weight: 600;
}

.tabfilter .css-at4ydg-MuiTabs-indicator {
    background: none !important;
}

.applyclosebtn {
    align-items: center;
    justify-content: space-around;
    width: 100%;
    border-top: 1px solid #939393;
    border-radius: 0 !important;
    padding: 10px 0;
    position: sticky;
    bottom: 0;
    background: #fff;
    z-index: 9;
}

.applyclosebtn button {
    color: #01A4EE;
    border: none;
    padding: 5px 0;
    width: 50%;
}

.applyclosebtn button:first-child {
    color: #000;
    border-right: 1px solid #939393 !important;
}

.applyclosebtn button:focus,
.applyclosebtn button:hover {
    outline: none;
    border: none;
    background: none;
}