.loginpage{
    width:100%;
    display:flex;
    align-items:center;
    height:100vh;
    justify-content:center;    
    position:relative;
   }
   .loginpage::before{
   content:'';    
   position:absolute;
   left:0;
   bottom:0;
   background:url(../../../public/images/loginbottemleft.svg) no-repeat left bottom;
   height: 497px;
   width: 320px;z-index: -1;
   }
   .loginpage::after{
   content:'';    
   position:absolute;
   right:0;
   top:0;
   background:url(../../../public/images/loginright.svg) no-repeat left bottom;
   height: 495px;    z-index: -1;
   width: 317px;
   }
   
  .loginbox {
   padding:50px;
   border: 1px solid #F0F0F0;
   width:350px;
   background: #FFFFFF;
   box-shadow: -5px 10px 20px rgba(0, 0, 0, 0.05) !important;
   border-radius: 10px;
   }
   .loginbox form{margin-top:50px;}