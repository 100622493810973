.assigneetitles {
  margin-bottom: 20px;
  display: grid;
}
.assigneetitles h4 {
  font-size: 15px;
  line-height: 18px;
  color: #817f7f;
  margin-bottom: 3px;
  font-weight: 700;
}

.assigneetitles h5 {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}
.assigneetitles h6 {
  font-size: 14px;
  line-height: 18px;
  color: #8d8d8d;
  margin-bottom: 0px;
  font-weight: 500;
  text-align: right;
}
.assigneetitles .notes-H5 {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  padding: 3px;
  display: block;
  border: none;
  margin-top: 5px;
}
.assigneetitles .notes-H6 {
  font-size: 14px;
  line-height: 18px;
  color: #8d8d8d;
  margin-bottom: 0px;
  font-weight: 500;
  text-align: right;
  padding: 3px;
  display: block;
  text-align: right;
  border: none;
}
/* given below CSS is for "NOTES" label in servicedetailsdrawer */
th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.notes-H4.css-1l33syf-MuiTableCell-root {
  font-size: 15px;
  line-height: 18px;
  color: #817f7f;
  margin-bottom: 3px;
  font-weight: 700;
  border-bottom: 0;
}
.assigneebtens {
  position: sticky;
  bottom: 0;
  padding: 20px 0;
  background-color: #fff;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  gap: 20px;
}
.assigneebtens button {
  width: 170px;
}
.assigneebtens button:first-child {
  margin-right: 20px;
}
.assigneetitlesselectinput {
  height: 35px;
}

.graybtn {
  background: #ececec !important;
  color: #000 !important;
}
.viewbtn {
  padding: 7px 18px;
  border-radius: 10px;
  border: none;
  background-color: #d8d8d8;
  align-items: center;
  color: #797070;
  font-weight: 600;
  cursor: pointer;
  /* margin: 0 125px; */
}
.assigneetitles .css-tteney-MuiTableHead-root .MuiTableCell-head {
  background: none;
  border: none;
}
.assigneetitles .css-tteney-MuiTableHead-root .MuiTableCell-head {
  padding: 0;
}
